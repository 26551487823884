import { render, staticRenderFns } from "./Focus.vue?vue&type=template&id=242d6032&scoped=true&"
import script from "./Focus.vue?vue&type=script&lang=js&"
export * from "./Focus.vue?vue&type=script&lang=js&"
import style0 from "./Focus.vue?vue&type=style&index=0&id=242d6032&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "242d6032",
  null
  
)

export default component.exports